/*******************************/
/********* General CSS *********/
/*******************************/
:root {
  --theme-color-1: #023047;
  --theme-color-2: #219ebc;
  --text-color-sm: #ffb703;
  --text-color-lg: #023047;
  --main-theme-text: #666666;
  --main-theme: #dff4ff;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  color: var(--main-theme-text);
  background: var(--main-theme);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--theme-color-1);
}

a {
  color: var(--main-theme-text);
  transition: 0.3s;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: var(--theme-color-2);
  outline: none;
  text-decoration: none;
}

.btn:focus {
  box-shadow: none;
}

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 1600px;
  margin: 0 auto;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  background: white;
}

.back-to-top {
  position: fixed;
  display: none;
  background: var(--theme-color-2);
  color: #121518;
  width: 44px;
  height: 44px;
  text-align: center;
  line-height: 1;
  font-size: 22px;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top:hover {
  color: var(--theme-color-2);
  background: #121518;
}

.back-to-top i {
  padding-top: 10px;
}

.btn {
  transition: 0.3s;
}

section {
  position: relative;
  width: 100%;
  height: 100%;
}

.main_content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
}

/**********************************/
/********** Top Bar CSS ***********/
/**********************************/
.top-bar {
  position: relative;
  height: 90px;
  /* background: var(--theme-color-2); */
  background: white;
}

.top-bar .logo {
  padding: 15px 0;
  text-align: left;
  overflow: hidden;
}

.top-bar .logo h1 {
  margin: 0;
  /* color: var(--theme-color-1); */
  /* color: white; */
  color: white;
  font-size: 46px;
  line-height: 60px;
  font-weight: 500;
  text-decoration: none;
  text-shadow: 0 3px 3px black;
}

@media (max-width: 999px) {
  .top-bar {
    display: none;
  }
}

@media (max-width: 767px) {
  .top-bar .logo h1 {
    font-size: 40px;
    line-height: 40px;
  }
  .top-bar {
    height: 70px;
  }
}

.top-bar .logo img {
  max-width: 100%;
  max-height: 60px;
  max-width: 100%;
  max-height: 75px;
  width: 250px;
  height: 74px;
}

.top-bar .top-bar-item {
  display: flex;
  align-items: center;
}

.top-bar .top-bar-icon {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-bar .top-bar-icon [class^='flaticon-']::before {
  margin: 0;
  color: var(--theme-color-1);
  font-size: 40px;
}

.top-bar .top-bar-text {
  padding-left: 15px;
}

.top-bar .top-bar-text h3 {
  margin: 0 0 5px 0;
  color: var(--theme-color-1);
  font-size: 16px;
  font-weight: 400;
}

.top-bar .top-bar-text p {
  margin: 0;
  color: var(--theme-color-1);
  font-size: 13px;
  font-weight: 400;
}

@media (min-width: 992px) {
  .top-bar {
    padding: 0 60px;
  }
}

@media (max-width: 991.98px) {
  .top-bar .logo {
    text-align: center;
  }
}

@media (max-width: 720px) {
  .top-bar {
    display: none;
  }
}

/**********************************/
/*********** Nav Bar CSS **********/
/**********************************/
.nav-bar {
  position: relative;
  /* background: var(--theme-color-2); */
  background: w;
  transition: 0.3s;
}

.navbar-brand {
  color: var(--text-color-sm);
  text-shadow: 0 3px 3px black;
}

.nav-bar .container-fluid {
  padding: 0;
}

.nav-bar.nav-sticky {
  position: fixed;
  top: 0;
  width: 100%;
  /* max-width: 1366px; */
  max-width: 1600px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 999;
}

.nav-bar .navbar {
  height: 100%;
  background: var(--theme-color-1) !important;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  padding: 10px 10px 8px 10px;
  color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--theme-color-2);
  transition: none;
}

.nav-bar .dropdown-menu {
  margin-top: 0;
  border: 0;
  border-radius: 0;
  background: #f8f9fa;
}

.nav-bar .btn {
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 0;
}

.nav-bar .btn:hover {
  color: var(--theme-color-1);
  background: var(--theme-color-2);
  border-color: var(--theme-color-2);
}

#contact-btn-nav-2 {
  display: block;
}

#contact-btn-nav-1 {
  display: none;
}

@media (max-width: 767px) {
  #contact-btn-nav-1 {
    display: block;
  }
  #contact-btn-nav-2 {
    display: none;
  }

  .nav-bar.nav-sticky {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .nav-bar {
    padding: 0 75px;
  }

  .nav-bar.nav-sticky {
    padding: 0;
  }

  .nav-bar .navbar {
    padding: 20px;
  }

  .nav-bar .navbar-brand {
    display: none;
  }

  .nav-bar a.nav-link {
    padding: 8px 15px;
    font-size: 15px;
    text-transform: uppercase;
  }
}

@media (max-width: 991.98px) {
  .nav-bar .navbar {
    padding: 15px;
    background: white !important;
  }

  .navbar-toggler {
    background: #030f27;
  }

  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #030f27;
    padding: 5px;
  }

  .nav-bar .dropdown-menu {
    box-shadow: none;
  }

  .nav-bar .btn {
    display: none;
  }
}

/*******************************/
/*********** FAQs CSS **********/
/*******************************/
.faqs {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.faqs .row {
  position: relative;
}

.faqs .row::after {
  position: absolute;
  content: '';
  width: 1px;
  height: 100%;
  top: 0;
  left: calc(50% - 0.5px);
  background: #fdbe33;
}

.faqs #accordion-1 {
  padding-right: 15px;
}

.faqs #accordion-2 {
  padding-left: 15px;
}

@media (max-width: 767.98px) {
  .faqs .row::after {
    display: none;
  }

  .faqs #accordion-1,
  .faqs #accordion-2 {
    padding: 0;
  }

  .faqs #accordion-2 {
    padding-top: 15px;
  }
}

.faqs .card {
  margin-bottom: 15px;
  border: none;
  border-radius: 0;
}

.faqs .card:last-child {
  margin-bottom: 0;
}

.faqs .card-header {
  padding: 0;
  border: none;
  background: #ffffff;
}

.faqs .card-header a {
  display: block;
  padding: 10px 25px;
  width: 100%;
  color: #121518;
  font-size: 16px;
  line-height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.faqs .card-header [data-toggle='collapse'][aria-expanded='true'] {
  background: #fdbe33;
}

.faqs .card-header [data-toggle='collapse']:after {
  font-family: 'font Awesome 5 Free';
  content: '\f067';
  float: right;
  color: #fdbe33;
  font-size: 12px;
  font-weight: 900;
  transition: 0.5s;
}

.faqs .card-header [data-toggle='collapse'][aria-expanded='true']:after {
  font-family: 'font Awesome 5 Free';
  content: '\f068';
  float: right;
  color: #030f27;
  font-size: 12px;
  font-weight: 900;
  transition: 0.5s;
}

.faqs .card-body {
  padding: 20px 25px;
  font-size: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
}

/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
  position: relative;
  margin: 45px 0;
  padding: 90px 0;
  text-align: center;
  background: linear-gradient(rgba(3, 15, 39, 0.9), rgba(3, 15, 39, 0.9)),
    url(../images/carousel3.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial .container {
  max-width: 760px;
}

.about-page .testimonial {
  padding-bottom: 90px;
}

.testimonial .testimonial-slider-nav {
  position: relative;
  width: 300px;
  margin: 0 auto;
}

.testimonial .testimonial-slider-nav .slick-slide {
  position: relative;
  opacity: 0;
  transition: 0.5s;
}

.testimonial .testimonial-slider-nav .slick-active {
  opacity: 1;
  transform: scale(1.3);
}

.testimonial .testimonial-slider-nav .slick-center {
  transform: scale(1.8);
  z-index: 1;
}

.testimonial .testimonial-slider-nav .slick-slide img {
  position: relative;
  display: block;
  margin-top: 37px;
  width: 100%;
  height: auto;
  border-radius: 100px;
}

.testimonial .testimonial-slider {
  position: relative;
  margin-top: 15px;
  padding-top: 50px;
}

.testimonial .testimonial-slider::before {
  position: absolute;
  content: '';
  width: 60px;
  height: 50px;
  top: 0;
  left: calc(50% - 30px);
  background: url(../images/quote.png) top center no-repeat;
}

.testimonial .testimonial-slider h3 {
  color: #fdbe33;
  font-size: 22px;
  font-weight: 700;
}

.testimonial .testimonial-slider h4 {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial .testimonial-slider p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

/*******************************/
/******** Carousel CSS *********/
/*******************************/
.carousel {
  position: relative;
  width: 100%;
  height: calc(100vh - 170px);
  min-height: 400px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}

.carousel .carousel-inner,
.carousel .carousel-item {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel .carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel .carousel-item::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.carousel .carousel-caption {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100vh - 170px);
  min-height: 400px;
  z-index: 50;
}

.carousel .carousel-caption p {
  color: #ffffff;
  font-size: 30px;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.carousel .carousel-caption h1 {
  color: #ffffff;
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 35px;
}

.carousel .carousel-caption .btn {
  padding: 15px 35px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #ffffff;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 0;
  transition: 0.3s;
}

.carousel .carousel-caption .btn:hover {
  color: var(--theme-color-1);
  background: var(--theme-color-2);
  border-color: var(--theme-color-2);
}

@media (max-width: 767.98px) {
  .carousel .carousel-caption h1 {
    font-size: 40px;
    font-weight: 700;
  }

  .carousel .carousel-caption p {
    font-size: 20px;
  }

  .carousel .carousel-caption .btn {
    padding: 12px 30px;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
  }
}

@media (max-width: 575.98px) {
  .carousel .carousel-caption h1 {
    font-size: 30px;
    font-weight: 500;
  }

  .carousel .carousel-caption p {
    font-size: 16px;
  }

  .carousel .carousel-caption .btn {
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
  }
}

.carousel .animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}

/*******************************/
/******* Page Header CSS *******/
/*******************************/
.page-header {
  position: relative;
  margin-bottom: 45px;
  padding: 90px 0;
  text-align: center;
  background: var(--theme-color-2);
}

.page-header h2 {
  position: relative;
  color: var(--theme-color-1);
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

.page-header h2::after {
  position: absolute;
  content: '';
  width: 100px;
  height: 2px;
  left: calc(50% - 50px);
  bottom: 0;
  background: var(--theme-color-1);
}

.page-header a {
  position: relative;
  padding: 0 12px;
  font-size: 22px;
  color: var(--theme-color-1);
}

.page-header a:hover {
  color: #ffffff;
}

.page-header a::after {
  position: absolute;
  content: '/';
  width: 8px;
  height: 8px;
  top: -2px;
  right: -7px;
  text-align: center;
  color: #121518;
}

.page-header a:last-child::after {
  display: none;
}

@media (max-width: 991.98px) {
  .page-header {
    padding: 60px 0;
  }

  .page-header h2 {
    font-size: 45px;
  }

  .page-header a {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .page-header {
    padding: 45px 0;
  }

  .page-header h2 {
    font-size: 35px;
  }

  .page-header a {
    font-size: 18px;
  }
}

/*******************************/
/******* Section Header ********/
/*******************************/
.section-header {
  position: relative;
  width: 100%;
  margin-bottom: 45px;
}

.section-header .Line {
  border: solid 1px #ff9408;
  height: 2px;
  width: 230px;
  /* margin: auto; */
}

.section-header p {
  color: #ff9408;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.section-header h2 {
  margin: 0;
  position: relative;
  font-size: 50px;
  font-weight: 700;
}

@media (max-width: 767.98px) {
  .section-header h2 {
    font-size: 30px;
  }
}

/*******************************/
/********* Feature CSS *********/
/*******************************/
.feature {
  position: relative;
  margin-bottom: 45px;
}

.feature .col-md-12 {
  background: var(--theme-color-1);
}

.feature .col-md-12:nth-child(2n) {
  color: var(--theme-color-1);
  background: var(--theme-color-2);
}

.feature .feature-item {
  min-height: 160px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.feature .feature-icon {
  position: relative;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature .feature-icon::before {
  position: absolute;
  content: '';
  width: 80px;
  height: 80px;
  top: -20px;
  left: -10px;
  border: 2px dotted #ffffff;
  border-radius: 60px;
  z-index: 1;
}

.feature .feature-icon::after {
  position: absolute;
  content: '';
  width: 79px;
  height: 79px;
  top: -18px;
  left: -9px;
  background: var(--theme-color-1);
  border-radius: 60px;
  z-index: 2;
}

.feature .col-md-12:nth-child(2n) .feature-icon::after {
  background: var(--theme-color-2);
}

.feature .feature-icon [class^='flaticon-']::before {
  position: relative;
  margin: 0;
  color: var(--theme-color-2);
  font-size: 60px;
  line-height: 60px;
  z-index: 3;
}

.feature .feature-text {
  padding-left: 30px;
}

.feature .feature-text h3 {
  margin: 0 0 10px 0;
  color: var(--theme-color-2);
  font-size: 25px;
  font-weight: 600;
}

.feature .feature-text p {
  margin: 0;
  color: var(--theme-color-2);
  font-size: 18px;
  font-weight: 400;
}

.feature .col-md-12:nth-child(2n) [class^='flaticon-']::before,
.feature .col-md-12:nth-child(2n) h3,
.feature .col-md-12:nth-child(2n) p {
  color: var(--theme-color-1);
}

/*******************************/
/********** About CSS **********/
/*******************************/
.about-container-wrapper {
  margin-bottom: -20px;
}
.about {
  position: relative;
  width: 100%;
  padding: 45px 0 0 0;
}

.about .section-header {
  margin-bottom: 30px;
}

.about .about-img {
  position: relative;
  height: 100%;
}

.about .about-img img {
  width: 100%;
  height: 100%;
  border-top-right-radius: 80px;
  border-bottom-left-radius: 80px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.35);
  object-fit: cover;
}

.about .about-text p {
  font-size: 18px;
  color: #282828;
  font-weight: 700;
}

/*******************************/
/********* more about CSS **********/
/*******************************/
.hide {
  display: none;
}
.About_sec {
  background-image: linear-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0.39)),
    url('../images/Group 54-min.png');
  padding: 35px 0px 88px 0px;
  width: 100%;
  overflow: hidden;
}
.About_sec .Center {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  text-align: center;
}

.About_sec h2 {
  margin: 0;
  position: relative;
  font-size: 50px;
  font-weight: 700;
}

.About_sec p {
  padding: 24px 0px 35px 0px;
  font-size: 14px;
  color: #282828;
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  line-height: 25px;
  font-weight: 700;
}
.About_sec .Line {
  border: solid 1px #ff9408;
  height: 2px;
  width: 252px;
  margin: auto;
}
.About_sec .Tabside {
  padding: 75px 0px;
  width: 100%;
}
.About_sec .Tabside ul {
  list-style: none;
  margin: 0px;
  padding-bottom: 59px;
}
.About_sec .Tabside li {
  float: none;
  padding: 0px;
  margin: 0px -2px;
  display: inline-block;
}
.tabLink {
  padding: 14px 40px 13px 40px;
  font-size: 18px;
  color: #989898;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  border: solid 1px #d5d5d5;
  display: block;
  transition: all 0.3s ease;
}

.activeLink {
  background-color: #ff9408;
  border: solid 1px #ff9408;
}
.About_sec .Tabside .TabImage {
  width: 43.63%;
  float: left;
  position: relative;
  height: 310px;
}
.About_sec .Tabside .TabImage .img1 {
  position: absolute;
  top: 0px;
  left: 42px;
  height: 260px;
  background: no-repeat;
  background-position: 25px 223px;
}
.About_sec .Tabside .TabImage .img1 img {
  padding: 5px;
  border: solid 1px #ececec;
  background: #fff;
}
.About_sec .Tabside .TabImage .img2 {
  position: absolute;
  top: 40px;
  left: 2px;
  height: 260px;
  background: bottom center no-repeat;
  background-position: 0px 223px;
}
.About_sec .Tabside .TabImage .img2 img {
  padding: 5px;
  border: solid 1px #ececec;
  background: #fff;
}
.About_sec .Tabside .Description {
  width: 54.3%;
  float: right;
  align-self: center;
  text-align: left;
  margin-top: 26px;
  position: relative;
  /* height: 100%; */
}

.Description h3 {
  font-size: 30px;
  font-weight: 700;
  margin: 0px;
  padding: 0px;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  line-height: 30px;
}

.Description span {
  color: var(--theme-color-2);
}

.About_sec .Tabside .Description h3 span {
  padding-left: 3px;
  font-size: 14px;
  color: #ff9000;
  display: block;
}
.About_sec .Tabside .Description p {
  padding: 21px 0px 4px 0px;
  font-size: 20px;
  /* color: #979797; */
  margin: 0px;
  font-family: 'Open Sans';
  line-height: 25px;
}

@media (max-width: 999px) {
  .About_sec .Tabside .Description {
    width: 40%;
  }
}

@media (max-width: 767.98px) {
  .About_sec .Tabside .TabImage {
    display: none;
  }
  .About_sec .Tabside .Description {
    text-align: center;
    width: auto;
    padding: 20px;
  }
  .about .about-img {
    margin-bottom: 30px;
    height: auto;
  }
}

/*******************************/
/********** Fact CSS ***********/
/*******************************/
.fact {
  position: relative;
  width: 100%;
  padding: 0 0;
}

.fact .col-6 {
  display: flex;
  align-items: flex-start;
}

.fact .fact-icon {
  position: relative;
  margin: 7px 15px 0 15px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fact .fact-icon [class^='flaticon-']::before {
  margin: 0;
  font-size: 60px;
  line-height: 60px;
  background-image: linear-gradient(#ffffff, var(--theme-color-2));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fact .fact-right .fact-icon [class^='flaticon-']::before {
  background-image: linear-gradient(#ffffff, var(--theme-color-1));
}

.fact .fact-left,
.fact .fact-right {
  padding-top: 60px;
  padding-bottom: 60px;
}

.fact .fact-text h2 {
  font-size: 35px;
  font-weight: 700;
}

.fact .fact-text p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

.fact .fact-left {
  color: var(--theme-color-2);
  background: var(--theme-color-1);
}

.fact .fact-right {
  color: var(--theme-color-1);
  background: var(--theme-color-2);
}

.fact .fact-left h2 {
  color: var(--theme-color-2);
}

/*******************************/
/******* Testimonial CSS *******/
/*******************************/
.testimonial {
  position: relative;
  margin: 45px 0;
  padding: 90px 0;
  text-align: center;
  background: linear-gradient(rgba(3, 15, 39, 0.9), rgba(3, 15, 39, 0.9)),
    url(../images/carousel3.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.testimonial .container {
  max-width: 760px;
}

.about-page .testimonial {
  padding-bottom: 90px;
}

.testimonial .testimonial-slider-nav {
  position: relative;
  width: 300px;
  margin: 0 auto;
}

.testimonial .testimonial-slider-nav .slick-slide {
  position: relative;
  opacity: 0;
  transition: 0.5s;
}

.testimonial .testimonial-slider-nav .slick-active {
  opacity: 1;
  transform: scale(1.3);
}

.testimonial .testimonial-slider-nav .slick-center {
  transform: scale(1.8);
  z-index: 1;
}

.testimonial .testimonial-slider-nav .slick-slide img {
  position: relative;
  display: block;
  margin-top: 37px;
  width: 100%;
  height: auto;
  border-radius: 100px;
}

.testimonial .testimonial-slider {
  position: relative;
  margin-top: 15px;
  padding-top: 50px;
}

.testimonial .testimonial-slider::before {
  position: absolute;
  content: '';
  width: 60px;
  height: 50px;
  top: 0;
  left: calc(50% - 30px);
  background: url(../images/quote.png) top center no-repeat;
}

.testimonial .testimonial-slider h3 {
  color: var(--theme-color-2);
  font-size: 22px;
  font-weight: 700;
}

.testimonial .testimonial-slider h4 {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  font-style: italic;
  margin-bottom: 10px;
}

.testimonial .testimonial-slider p {
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
}

/*******************************/
/******** Portfolio CSS ********/
/*******************************/
.bg-portfolio {
  background:
    linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../images/carousel3.jpg),
    no-repeat center center;
  background-size: cover;
}

.portfolio-item .portfolio-img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.portfolio-item:hover .portfolio-img {
  top: 100%;
}

.portfolio-item .portfolio-text {
  background: var(--text-color-lg);
  position: absolute;
  width: 100%;
  height: 100%;
  top: -100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.portfolio-text h4 {
  color: white;
}

.portfolio-text p {
  padding: 10px;
  color: white;
}

.portfolio-item:hover .portfolio-text {
  top: 0;
}

.team .team-img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

/*******************************/
/********* Contact CSS *********/
/*******************************/
.contact {
  position: relative;
  width: 100%;
  padding: 45px 0;
}

.contact .col-md-6 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.contact .col-md-6:first-child {
  background: var(--theme-color-1);
}

.contact .col-md-6:last-child {
  background: var(--theme-color-2);
}

.contact .contact-info {
  position: relative;
  width: 100%;
  padding: 0 15px;
}

.contact .contact-item {
  position: relative;
  margin-bottom: 30px;
  padding: 30px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  border: 1px solid rgba(256, 256, 256, 0.2);
}

.contact .contact-item [class^='flaticon-']::before {
  margin: 0;
  color: var(--theme-color-2);
  font-size: 40px;
}

.contact .contact-text {
  position: relative;
  width: auto;
  padding-left: 20px;
  word-wrap: break-word;
  word-break: break-all;
}

.contact .contact-text h2 {
  color: var(--theme-color-2);
  font-size: 20px;
  font-weight: 600;
}

.contact .contact-text p {
  margin: 0;
  color: #ffffff;
  font-size: 16px;
  /* text wrap */
  word-wrap: break-word;
}

.contact .contact-item:last-child {
  margin-bottom: 0;
}

.contact .contact-form {
  position: relative;
  padding: 0 15px;
}

.contact .contact-form input {
  color: #ffffff;
  height: 40px;
  border-radius: 0;
  border-width: 1px;
  border-color: rgba(256, 256, 256, 0.4);
  background: transparent;
}

.contact .contact-form textarea {
  color: #ffffff;
  height: 185px;
  border-radius: 0;
  border-width: 1px;
  border-color: rgba(256, 256, 256, 0.4);
  background: transparent;
}

.contact .contact-form input:focus,
.contact .contact-form textarea {
  box-shadow: none;
}

.contact .contact-form .form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.contact .contact-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.contact .contact-form .form-control::-ms-input-placeholder {
  color: #ffffff;
}

.contact .contact-form .btn {
  padding: 16px 30px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color-2);
  background: var(--theme-color-1);
  border: none;
  border-radius: 0;
  transition: 0.3s;
}

.contact .contact-form .btn:hover {
  color: var(--theme-color-1);
  background: #ffffff;
}

.contact .help-block ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

/*******************************/
/********* Footer CSS **********/
/*******************************/
.footer {
  position: relative;
  margin-top: -6px;
  padding-top: 90px;
  background: var(--theme-color-1);
  color: #ffffff;
}

.footer .footer-contact,
.footer .footer-link,
.footer .newsletter {
  position: relative;
  margin-bottom: 45px;
}

.footer h2 {
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  color: var(--theme-color-2);
}

.footer h2::after {
  position: absolute;
  content: '';
  width: 60px;
  height: 2px;
  left: 0;
  bottom: 0;
  background: var(--theme-color-2);
}

.footer .footer-link a {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #ffffff;
  transition: 0.3s;
}

.footer .footer-link a:hover {
  color: var(--theme-color-2);
  letter-spacing: 1px;
}

.footer .footer-contact p i {
  width: 25px;
}

.footer .footer-social {
  position: relative;
  margin-top: 20px;
}

.footer .footer-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 7px 0;
  text-align: center;
  border: 1px solid rgba(256, 256, 256, 0.3);
  border-radius: 60px;
  transition: 0.3s;
  margin: 0 5px;
}

.footer .footer-social a i {
  font-size: 15px;
  color: #ffffff;
}

.footer .footer-social a:hover {
  background: var(--theme-color-2);
  border-color: var(--theme-color-2);
}

.footer .footer-social a:hover i {
  color: var(--theme-color-1);
}

.footer .footer-menu .f-menu {
  position: relative;
  padding: 15px 0;
  font-size: 0;
  text-align: center;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
  border-bottom: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .footer-menu .f-menu a {
  color: #ffffff;
  font-size: 16px;
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu .f-menu a:hover {
  color: var(--theme-color-2);
}

.footer .footer-menu .f-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.footer .copyright {
  padding: 30px 15px;
}

.footer .copyright p {
  margin: 0;
  color: #ffffff;
}

.footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footer .copyright p a {
  color: var(--theme-color-2);
  font-weight: 500;
  letter-spacing: 1px;
}

.footer .copyright p a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer .copyright p,
  .footer .copyright .col-md-6:last-child p {
    margin: 5px 0;
    text-align: center;
  }
}

/*******************************/
/********* services CSS **********/
/*******************************/
.service {
  padding: 45px 15px;
}
.service-card {
  color: var(--text-color-lg);
  background-size: cover;
  padding: 10rem 0 0;
  max-width: 34ch;
  height: 330px;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 500ms ease;
  position: relative;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.25);
}

.service-card img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.service-card:hover {
  transform: scale(1.05);
}

.service-card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(rgba(4, 23, 51, 0.9), rgba(4, 24, 51, 0.6), rgba(3, 38, 90, 0.7));
  height: 100%;
  transform: translateY(36%);
}

.service-card:hover .service-card-content {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5));
}

.service-card:hover h2 {
  color: white;
}

.service-card-content h2 {
  color: white;
  font-size: 1.2rem;
}

.service-card-title {
  position: relative;
  width: max-content;
}

.service-card-title::after {
  content: '';
  position: absolute;
  left: calc(var(--padding) * -1);
  bottom: -4px;
  height: 3px;
  width: calc(100% + var(--padding));
  background: var(--text-color-sm);
  transform-origin: left;
  transition: transform 500ms ease;
}

.service-card:hover .service-card-title::after,
.service-card:focus-within .service-card-title::after {
  transform: scaleX(1);
}

.serivce-button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: white;
  opacity: 1;
  background: var(--theme-color-2);
  padding: 0.5em 1.25em;
  border-radius: 0.25em;
  margin-top: 5px;
}

.serivce-button:hover,
.serivce-button:focus {
  background-color: var(--theme-color-1);
}

.addmorebtn .btn {
  padding: 16px 30px;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-color-2);
  background: var(--theme-color-1);
  border: none;
  border-radius: 0;
  transition: 0.3s;
}

.addmorebtn .btn:hover {
  color: var(--theme-color-1);
  background: var(--theme-color-2);
}

@media (hover) {
  .service-card-content {
    transform: translateY(65%);
    transition: transform 500ms ease;
  }
  .service-card-content > *:not(.service-card-title) {
    opacity: 0;
    transition: opacity 500ms linear;
  }
  .service-card:hover .service-card-content,
  .service-card:focus-within .service-card-content {
    transform: translateY(35%);
    transition-delay: 500ms;
  }

  .service-card:focus-within .service-card-content {
    transition-duration: 0ms;
  }

  .service-card:hover .service-card-content > *:not(.service-card-title),
  .service-card:focus-within .service-card-content > *:not(.service-card-title) {
    opacity: 1;
    transition-delay: 800ms;
  }

  .service-card-title::after {
    transform: scaleX(0);
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}

@media (max-width: 767px) {
  .main-service-con {
    padding: 10px;
  }
}

/*******************************/
/********* partners CSS **********/
/*******************************/
#partners {
  width: 100%;
  background-color: rgb(244, 244, 244);
  padding: 150px 0;
}

#partners img {
  /* use multi blend */
  mix-blend-mode: multiply;
}

/*******************************/
/********* timeline CSS **********/
/*******************************/
.timeline {
  display: flex;
  text-align: center;
  flex-direction: column;
  max-width: 350px;
  max-height: 400px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.timeline-head {
  margin-top: -146px;
  background: var(--text-color-lg);
  padding: 20px;
  color: var(--text-color-sm);
}

.timeline-head h1 {
  color: var(--text-color-sm);
}

.timeline-social {
  background-color: var(--text-color-lg);
}

.timeline-social a {
  background-color: var(--theme-color-2);
}

.timeline-text {
  background-color: var(--text-color-lg);
}

.timeline .timeline-img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
}

.timeline:hover .timeline-img {
  left: 75px;
}

.timeline .timeline-social {
  position: absolute;
  width: 75px;
  top: 0;
  bottom: 0;
  left: -75px;
  z-index: 1;
  transition: 0.5s;
}

.timeline:hover .timeline-social {
  left: 0;
}

.timeline-carousel {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  padding: 30px;
}

@media (max-width: 1080px) {
  .timeline-carousel {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .timeline-carousel {
    grid-template-columns: repeat(1, 1fr);
  }
}

/*******************************/
/********* loader css **********/
/*******************************/
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 200px;
  height: 200px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3498db;

  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

  z-index: 10000;
}

#loader:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #e74c3c;

  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #f9c922;

  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #023047;
  z-index: 1000;
  -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0); /* IE 9 */
  transform: translateX(0); /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%); /* IE 9 */
  transform: translateX(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%); /* IE 9 */
  transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;

  -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%); /* IE 9 */
  transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

/* JavaScript Turned Off */
.no-js #loader-wrapper {
  display: none;
}
.no-js h1 {
  color: #023047;
}

.con-spin {
  position: fixed;
  top: 50%;
  left: 46%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: auto;
  height: auto;
}

.innerpoint {
  position: absolute;
  z-index: 3000;
  left: 69%;
  top: 24%;
  transform: translate(-50%, -50%);
}

.innerpoint img {
  background: #023047;
  width: 96px;
  -webkit-animation: scale 1s ease-in-out infinite alternate;
  animation: scale 1s ease-in-out infinite alternate;
  border-radius: 100%;
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(0.8);
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(0.6);
  }
  100% {
    -webkit-transform: scale(0.8);
  }
}

h1.heading {
  color: #fff;
  font-size: 1.15em;
  font-weight: 900;
  margin: 0 0 0.5em;
  color: #505050;
}
@media (min-width: 450px) {
  h1.heading {
    font-size: 3.55em;
  }
}
@media (min-width: 760px) {
  h1.heading {
    font-size: 3.05em;
  }
}
@media (min-width: 900px) {
  h1.heading {
    font-size: 3.25em;
    margin: 0 0 0.3em;
  }
}

.card {
  display: block;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border-radius: 2px;
  box-shadow:
    0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.25s;
}
.card:hover {
  box-shadow:
    0 8px 17px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-card {
  width: 100%;
  height: 200px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  display: block;
  overflow: hidden;
}
.img-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 0.25s ease;
}
.card-content {
  padding: 15px;
  text-align: left;
}
.card-title {
  margin-top: 0px;
  font-weight: 700;
  font-size: 1.65em;
}
.card-title a {
  color: #000;
  text-decoration: none !important;
}
.card-read-more {
  border-top: 1px solid #d4d4d4;
}
.card-read-more a {
  text-decoration: none !important;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
}

/**************************************/
/********* text carousel css **********/
/**************************************/
